import React, { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useStyles } from './styles';
import TableLoading from '../TableLoading';
import Card from '@mui/material/Card';
import api, { exceptionNotificationAPI } from '../../services/api';

const AnalyseSampleDetailItemInterpretationRecommendationResult = ({ place, parameterTemplateItem, _lstAnalyseSampleResultInterpretation, analyseSampleID, analyseSampleParameterTemplateID }) => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [lstAnalyseSampleResultInterpretation, setLstAnalyseSampleResultInterpretation] = useState(_lstAnalyseSampleResultInterpretation.item1);
    const [lstParameterTemplateItem, setLstParameterTemplateItem] = useState(_lstAnalyseSampleResultInterpretation.item2);

    useEffect(() => {
        let cancel = false;
        cancel = true;

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    useEffect(() => {
        let cancel = false;

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, [place, parameterTemplateItem, _lstAnalyseSampleResultInterpretation, analyseSampleID, analyseSampleParameterTemplateID]);

    if (isLoading) {
        return (
            <Box style={{ width: "90%" }}>
                <TableLoading />
            </Box>
        )
    }

    return (
        <>
            {
                place == 0 ?
                    <>
                        {
                            <Box >
                                <Grid alignItems="flex-start" style={{ marginTop: "20px" }} container spacing={1}>
                                    {
                                        lstAnalyseSampleResultInterpretation ? lstAnalyseSampleResultInterpretation.map(e => {
                                            return (
                                                <Grid item xs={3} spacing={5}>
                                                    {
                                                        e.parameterTemplateItemID !== null && e.parameterTemplateItem.parameterTemplateItemHeader.description ?
                                                            <label className={styles.tableLabelsHeader}>{e.parameterTemplateItem.parameterTemplateItemHeader.description} </label>
                                                            :
                                                            e.parameterTemplateItem !== null && e.parameterTemplateItem.dependencyParameterTemplateItemID ?
                                                                <label className={styles.tableLabelsHeader}>{e.parameterTemplateItem.dependencyParameterTemplateItem.parameterTemplateItemHeader.dependencyAnalyseTypeParameter.parameter.description} + {e.item1.parameterTemplateItem.parameterTemplateItemHeader.analyseTypeParameter.parameter.description}</label>
                                                                :
                                                                <label className={styles.tableLabelsHeader}>{e.analyseSampleResult.parameter.description} </label>
                                                    }

                                                    <Grid item xs={12} alignItems="flex-start" style={{ marginTop: "5px" }} container >
                                                        {
                                                            e.parameterTemplateItem != null && e.parameterTemplateItem.dependencyParameterTemplateItemID ?
                                                                <Grid item xs={12}>
                                                                    <Grid alignItems="flex-start" container spacing={1}>
                                                                        <Grid item xs={3}>
                                                                            <input className={styles.input} style={{ width: "100%", background: "#F2F5F8" }} readOnly defaultValue={(e.analyseSampleResult.parameter.roundingRule != null ?  e.analyseSampleResult.value.toFixed(e.analyseSampleResult.parameter.roundingRule) : e.analyseSampleResult.value)}></input>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <input className={styles.input} style={{ width: "100%", background: "#F2F5F8" }} readOnly defaultValue={e.analyseSampleResultDependency.value}></input>
                                                                        </Grid>
                                                                        {
                                                                            e.parameterTemplateItem.resultColor ?
                                                                                <Grid item xs={5} style={{ position: "relative" }}>
                                                                                    <input style={{ position: "absolute", marginLeft: "5%", marginTop: "13px", outline: "none", borderRadius: "5px", border: "1px solid " + e.parameterTemplateItem.resultColor, width: "10%", height: "35%", background: e.parameterTemplateItem.resultColor }} readOnly ></input>
                                                                                    <input className={styles.input} style={{ width: "100%", paddingLeft: "20%" }} readOnly defaultValue={e.parameterTemplateItem.resultDescription}></input>
                                                                                </Grid>
                                                                                :
                                                                                <Grid item xs={5} style={{ position: "relative" }}>
                                                                                    <input className={styles.input} style={{ width: "100%", paddingLeft: "20%" }} readOnly defaultValue={e.parameterTemplateItem.resultDescription}></input>
                                                                                </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                e ?
                                                                    <Grid item xs={12}>
                                                                        <Grid alignItems="flex-start" container spacing={1}>
                                                                            <Grid item xs={6}>
                                                                                <input className={styles.input} style={{ width: "100%", background: "#F2F5F8" }} readOnly defaultValue={(e.analyseSampleResult.parameter.roundingRule != null ?  e.analyseSampleResult.value.toFixed(e.analyseSampleResult.parameter.roundingRule) : e.analyseSampleResult.value)}></input>
                                                                            </Grid>
                                                                            {
                                                                                e.parameterTemplateItem != null && e.parameterTemplateItem.resultColor ?
                                                                                    <Grid item xs={5} style={{ position: "relative" }}>
                                                                                        <input style={{ position: "absolute", marginLeft: "5%", marginTop: "13px", outline: "none", borderRadius: "5px", border: "1px solid " + e.parameterTemplateItem.resultColor, width: "10%", height: "35%", background: e.parameterTemplateItem.resultColor }} readOnly ></input>
                                                                                        <input className={styles.input} style={{ width: "100%", paddingLeft: "20%" }} readOnly defaultValue={e.parameterTemplateItem.resultDescription}></input>
                                                                                    </Grid>
                                                                                    :
                                                                                    e.parameterTemplateItem != null ?
                                                                                        <Grid item xs={5} style={{ position: "relative" }}>
                                                                                            <input className={styles.input} style={{ width: "100%", paddingLeft: "20%" }} readOnly defaultValue={e.parameterTemplateItem.resultDescription}></input>
                                                                                        </Grid>
                                                                                        :
                                                                                        <></>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    :
                                                                    <></>
                                                        }

                                                    </Grid>
                                                </Grid>

                                            )
                                        })
                                            :
                                            <></>
                                    }
                                </Grid>
                            </Box>
                        }
                    </>
                    :
                    <>
                        {
                            lstParameterTemplateItem ? lstParameterTemplateItem.map(e => {
                                return (

                                    <Box >
                                        {
                                            
                                            e.listParameterTemplateItemRecommendationDto.length > 0 ?
                                                <Grid alignItems="flex-start" style={{ marginTop: "20px" }} container spacing={5}>
                                                    <Grid item xs={12}>
                                                        {
                                                            <label className={styles.tableLabelsHeader}>{e.parameterTemplateItemHeader.analyseTypeParameter.parameter.description} </label>
                                                        }
                                                    </Grid>
                                                    {
                                                        e.listParameterTemplateItemRecommendationDto.map(p => {
                                                            return (
                                                                <Grid item xs={6} style={{ marginTop: "-2%" }}>
                                                                    <Card style={{ "backgroundColor": "#F2F5F8", height: "auto" }}>
                                                                        {
                                                                            <Box style={{ display: "column" }}>
                                                                                <Grid style={{ display: "flex", marginLeft: "1%", marginTop: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                    <Grid item xs={12}>
                                                                                        <label style={{ fontWeight: 600, }} >{p.title}</label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                    <Grid item xs={6}>
                                                                                        <label>Fertilizante</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <label>Quantidade</label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                    <Grid item xs={6} style={{ marginTop: "-2%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.recommendation.description}</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} style={{ marginTop: "-2%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.amount} {p.recommendation.unitOfMeasure.description} {p.recommendation.type}</label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {
                                                                                    p.instruction ?
                                                                                        <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                            <Grid item xs={12}>
                                                                                                <label>Instrução de Dosagem</label>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                {
                                                                                    p.instruction ?
                                                                                        <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                            <Grid item xs={6} style={{ marginTop: "-2%" }}>
                                                                                                <label style={{ fontWeight: 600 }} >{p.instruction}</label>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                {
                                                                                    p.observation ?
                                                                                        <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                            <Grid item xs={12}>
                                                                                                <label>Observações</label>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                {
                                                                                    p.observation ?
                                                                                        <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={5}>
                                                                                            <Grid item xs={6} style={{ marginTop: "-2%" }}>
                                                                                                <label style={{ fontWeight: 600 }} >{p.observation}</label>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <Grid item xs={12} >
                                                                                    <label></label>
                                                                                </Grid>
                                                                            </Box>

                                                                        }
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                    }

                                                </Grid>

                                                :
                                                <></>
                                        }

                                    </Box>
                                )
                            })
                            :
                                <></>
                        }
                        {
                            lstAnalyseSampleResultInterpretation ? lstAnalyseSampleResultInterpretation.map(e => {
                                return (
                                    <Box >
                                        {
                                            e.listRecommendationResultDto != undefined && e.listRecommendationResultDto.length > 0 ?
                                                <Grid alignItems="flex-start" style={{ marginTop: "40px" }} container spacing={3}>
                                                    <Grid item xs={12}>
                                                        {
                                                            <label className={styles.tableLabelsHeader}>Roteiro de Aplicação NPK</label>
                                                        }
                                                    </Grid>
                                                    {
                                                        e.listRecommendationResultDto.map(p => {
                                                            return (
                                                                <Grid item xs={4} style={{ marginTop: "-1%" }}>
                                                                    <Card style={{ "backgroundColor": "#F2F5F8", height: "auto" }}>
                                                                        {
                                                                            <Box style={{ display: "column" }}>
                                                                                <Grid style={{ display: "flex", marginLeft: "1%", marginTop: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                    <Grid item xs={12}>
                                                                                        <label style={{ fontWeight: 600, }} >{p.fertilizerCompositionStage.stage.description}</label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid style={{ display: "flex", marginLeft: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                    <Grid item xs={2}>
                                                                                        <label>Kg/ha</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <label>N</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <label>P2O5</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <label>K2O</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <label>Composição</label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid style={{ display: "flex", marginLeft: "1%", marginRight: "1%" }} alignItems="flex-start" container spacing={1}>
                                                                                    <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.total}</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.total_N.toFixed(0)}</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.total_P.toFixed(0)}</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} style={{ marginTop: "-1%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.total_K.toFixed(0)}</label>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} style={{ marginTop: "-1%" }}>
                                                                                        <label style={{ fontWeight: 600 }} >{p.fertilizerCompositionStage.fertilizerComposition.description}</label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={12} >
                                                                                    <label></label>
                                                                                </Grid>
                                                                            </Box>
                                                                        }
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>

                                                :
                                                <></>
                                        }
                                    </Box>
                                )
                            })
                            :
                                <></>
                        }
                    </>
            }
        </>
    );
}

export default AnalyseSampleDetailItemInterpretationRecommendationResult;
